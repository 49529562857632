import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useCallback } from 'react';
import { getMetadata } from 'helpers';
import { PageTemplate } from 'gatsby-theme-ww-prismic/src/templates/page';
import { useNewsPagination } from 'src/hooks';
export var NewsTemplate = function NewsTemplate(_ref) {
  var _metadata$items;

  var data = _ref.data,
      location = _ref.location,
      pageContext = _ref.pageContext,
      props = _objectWithoutPropertiesLoose(_ref, ["data", "location", "pageContext"]);

  var page = data === null || data === void 0 ? void 0 : data.prismicPage;
  var metadata = getMetadata('PrismicPageMainNews', page);
  var relatedProjects = metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : _metadata$items.filter(function (item) {
    var _item$related_project;

    return Boolean(item === null || item === void 0 ? void 0 : (_item$related_project = item.related_project) === null || _item$related_project === void 0 ? void 0 : _item$related_project.document);
  });
  var uid = pageContext === null || pageContext === void 0 ? void 0 : pageContext.uid;

  var _useNewsPagination = useNewsPagination(uid),
      prev = _useNewsPagination.prev,
      next = _useNewsPagination.next;

  var injectSlices = useCallback(function (list) {
    var _prev$data, _prev$data$title, _next$data, _next$data$title;

    if (list === void 0) {
      list = [];
    }

    return [{
      __typename: 'PageBodyHeader',
      id: 'header'
    }, {
      __typename: 'PageBodyNewsIntro',
      id: 'news-intro'
    }].concat(_toConsumableArray(list), [{
      __typename: 'PageBodyPrevNext',
      id: 'prev-next',
      primary: {
        prev_link: {
          url: prev === null || prev === void 0 ? void 0 : prev.url
        },
        prev_label: {
          text: prev === null || prev === void 0 ? void 0 : (_prev$data = prev.data) === null || _prev$data === void 0 ? void 0 : (_prev$data$title = _prev$data.title) === null || _prev$data$title === void 0 ? void 0 : _prev$data$title.text
        },
        next_link: {
          url: next === null || next === void 0 ? void 0 : next.url
        },
        next_label: {
          text: next === null || next === void 0 ? void 0 : (_next$data = next.data) === null || _next$data === void 0 ? void 0 : (_next$data$title = _next$data.title) === null || _next$data$title === void 0 ? void 0 : _next$data$title.text
        }
      }
    }, relatedProjects.length > 0 && {
      __typename: 'PageBodyProjectCards',
      id: 'project-cards',
      primary: {
        list_name: {
          text: 'Related Projects'
        }
      },
      items: relatedProjects.map(function (item) {
        item.project = item.related_project;
        return item;
      })
    }, {
      __typename: 'PageBodyFooter',
      id: 'footer'
    }]).filter(Boolean);
  }, [prev, next, relatedProjects]);
  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    listMiddleware: injectSlices
  }, props));
};
export default NewsTemplate;
export var query = "1604691817";